import Vue from "vue";
import App from "./App.vue";
// import Chat from '/Users/mopeiji/Desktop/test/vue-beautiful-chat/dist/vue-beautiful-chat.umd.min.js'
import Chat from "@/common/vue-beautiful-chat.umd.min.js";
Vue.use(Chat);
Vue.config.productionTip = false;
import i18n from "./i18n";
import amplitude from "amplitude-js";
amplitude.init("c513d26b24135b95a9b07c57ff6fa8b2");
Vue.prototype.$amplitude = amplitude;
import router from "./router"; // Import the router

// 控制是否携带默认埋点参数
const ENABLE_DEFAULT_TRACKING = true; // 这里可以控制是否默认带参数

if (typeof window !== "undefined") {
  localStorage.setItem("referrer", document.referrer);
}

Vue.prototype.$trackEvent = function (
  eventName,
  customData = {},
  includeDefaults = ENABLE_DEFAULT_TRACKING
) {
  if (this.$amplitude) {
    const defaultData = includeDefaults
      ? {
          url: window.location.href,
          utm: this.$route?.query?.utm_source || "",
          referrer: localStorage.getItem("referrer") || "",
        }
      : {}; // 如果 includeDefaults 为 false，则不添加默认参数

    this.$amplitude.getInstance().logEvent(eventName, {
      ...defaultData, // 合并默认参数
      ...customData, // 允许传入自定义参数
    });
  }
};

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");
